import axios, { AxiosResponse } from "axios";
import { GameAnswerData, GameResponseData } from '../../@types/game';

const API_URL = process.env.REACT_APP_GAME_API_URL;

const getGameData = async (gameUid: string): Promise<GameResponseData> => {
  const response: AxiosResponse<GameResponseData> = await axios.get(API_URL + gameUid );

  return response.data
};

const postGameAnswer = async (gameUid: string, answerData: GameAnswerData): Promise<GameResponseData> => {
  const response: AxiosResponse<GameResponseData> = await axios.post(API_URL + gameUid + '/play-guest', answerData);

  return response.data
};

const gameServices = {
  getGameData,
  postGameAnswer
};

export default gameServices;