import React from 'react';
import './Footer.scss'

export const Footer = () => {
  return (
    <footer className='footer-container'>
      <span>
        <strong>
          <a href='https://socialness.us/terms-of-use-for-socialness' target='_blank' rel="noreferrer">Terms of Service</a>
        </strong>
         <span>and</span>
        <strong>
          <a href='https://socialness.us/privacy-policy-for-socialness/' target='_blank' rel="noreferrer">Privacy Policy</a>
        </strong>
      </span>
    </footer>
  );
};

