import React from 'react';
import './TryItYourself.scss';
import { Trans, useTranslation } from 'react-i18next';
import { GlobalState } from '../../@types/game';
import { useAppSelector } from '../../store/hooks';
import qrImage from '../../assets/images/appQrCode.svg';
import googleAppImage from '../../assets/images/googleApp.png'
import iosAppImage from '../../assets/images/iosApp.png'

export const TryItYourself = () => {
  const { t } = useTranslation();
  const { gameData } = useAppSelector((state: GlobalState) => state.game);
  const question = gameData.gameQuestion.split('\n')[0];

  return (
    <div className='try-it'>
      <div className='try-it__info-container'>
        <div className='info-block'>
          <div>
            <h2>{t('tryIt_info-block-title1')}</h2>
            <p>{t('tryIt_info-block-text1')}</p>
          </div>
          <h2>{t('tryIt_info-block-title2')}</h2>
          <p>{t('tryIt_info-block-text2')}</p>
        </div>
        <div className='app-block'>
         <div className='app-info-container'>
           <div className='app-text'>
             <h2>{t('tryIt_info-app-block-title')}</h2>
             <p>{t('tryIt_info-app-block-text.part1')}</p>
             <ul>
               <Trans
                 i18nKey="tryIt_info-app-block-text.part2"
                 defaults="<li>Understand your social fitness</li> <li>Work on your ‘social muscles’</li> <li>Build and maintain your social connections</li>"
                 components={{li: <li/> }}
               />
             </ul>
             <p>{t('tryIt_info-app-block-text.part3')}</p>
           </div>
           <div className='app-qr'>
             <img src={qrImage}/>
           </div>
         </div>
          <div className='app-download-container'>
            <a href='/#' target='_blank'>
              <img className='app-download-container__image' src={googleAppImage} alt='Downdload google app'/>
            </a>
            <a href='/#' target='_blank'>
              <img className='app-download-container__image' src={iosAppImage} alt='Downdload iOS app'/>
            </a>
          </div>
        </div>
      </div>

      <div className='try-it__question-container'>
        <img className='question-img' src={gameData.gameImg} alt='question image'/>
        <div className='question-text'>{question}</div>
      </div>
    </div>
  )
};
