import {createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GameState, GameData, GameAnswerData, PostGameAnswerParams } from '../../@types/game';
import gameServices from '../services/gameServices';
import { S3ImageParser } from '../helpers/helpers';
import  demoGameImage  from '../../assets/images/demoGameImage.png';

export const getGameData = createAsyncThunk<any, string, { rejectValue: any }>('getGameData', async (id: string, { rejectWithValue }) => {
  try {
    return await gameServices.getGameData(id)
  } catch (error: any) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

    return rejectWithValue(message)
  }
});

export const postGameAnswerData = createAsyncThunk<any, PostGameAnswerParams, { rejectValue: any }>('postGameAnswer', async ({ gameUid, answerData }, { rejectWithValue }) => {
  try {
    return await gameServices.postGameAnswer(gameUid, answerData)
  } catch (error: any) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

    return rejectWithValue(message)
  }
});

const demoGameData: GameData = {
  gameUid: '',
  gameImg: demoGameImage,
  gameQuestion: 'Where is this?',
  clickCount: 0,
  hidingType: 'square',
  points: 2,
  isGameStarted: false,
  isTimerRunning: false
};

const initialState: GameState = {
  gameData: {
    gameUid: '',
    gameImg: '',
    gameQuestion: '',
    clickCount: 0,
    points: 2,
    hidingType: '',
    gameAnswerTime: '',
    isGameStarted: false,
    isTimerRunning: false
  },
  isError: false,
  isLoading: false,
  isSuccess: false,
  errorMessage: ''
};

export const gameSlice = createSlice({
  name: 'gameData',
  initialState,
  reducers: {
    incrementSteps: (state) => {
      state.gameData.clickCount += 1
    },
    startGame: (state) => {
      state.gameData.isGameStarted = true
    },
    startGameTimer: (state) => {
      state.gameData.isTimerRunning = true
    },
    stopGameTimer: (state) => {
      state.gameData.isTimerRunning = false
    },
    setDemoGameData: (state) => {
      state.gameData = demoGameData
    },
    setAnswerTime: (state, action) => {
      state.gameData.gameAnswerTime = action.payload
    },
    resetStatuses: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGameData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getGameData.fulfilled,  (state, actions) => {
        state.isLoading = false
        state.isSuccess = true
        state.gameData.gameUid = actions.payload.data.game.gameUid
        state.gameData.gameQuestion = actions.payload.data.game.question
        state.gameData.gameImg = S3ImageParser(actions.payload.data.game.picture)
        state.gameData.hidingType = actions.payload.data.game.obscureType
      })
      .addCase(getGameData.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.errorMessage = action.payload
      })
      .addCase(postGameAnswerData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(postGameAnswerData.fulfilled,  (state, actions) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(postGameAnswerData.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.errorMessage = action.payload
      })
  },
})

export const {
  incrementSteps,
  startGame,
  startGameTimer,
  stopGameTimer,
  setDemoGameData,
  setAnswerTime,
  resetStatuses
} = gameSlice.actions

export default gameSlice.reducer