import React, { useState, useEffect } from 'react';
import { setAnswerTime } from '../../store/slices/gameSlice';
import { useDispatch } from 'react-redux';

interface Props {
  isTimerRunning: boolean,
  gameAnswerTime?: string
}

export const Timer = ({ isTimerRunning, gameAnswerTime }: Props) => {
  const dispatch = useDispatch();
  const startMinutes: number = 1;
  const startSeconds:number = 30;
  const [minutes, setMinutes] = useState(startMinutes);
  const [seconds, setSeconds] = useState(startSeconds);
  const [isRunning, setIsRunning] = useState<boolean>( false);
  

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (isTimerRunning) {
      startTimer();
    } else {
      stopTimer();
    }

    if ((!isRunning && (answerTime() !== gameAnswerTime)) || answerTime() === '90') {
      dispatch(setAnswerTime(answerTime()));
    }

    if (isRunning) {
      timer = setInterval(() => {

        if (minutes === 0 && seconds === 0) {
          clearInterval(timer);
        } else if (seconds === 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          setSeconds(seconds - 1);
        }
      }, 1000);
    } else {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [isRunning, isTimerRunning, minutes, seconds]);

  const startTimer = () => {
    setIsRunning(true);
  };

  const stopTimer = () => {
    setIsRunning(false);
  };
  const formatTime = (value: number) => (value < 10 ? `0${value}` : value);

  const answerTime = (): string => {
    const endMinutes = minutes;
    const endSeconds = seconds;
    const startTimeInSeconds = startMinutes * 60 + startSeconds;
    const endTimeInSeconds = endMinutes * 60 + endSeconds;
    const timeDifferenceInSeconds = startTimeInSeconds - endTimeInSeconds;

    return String(timeDifferenceInSeconds);
  }

  return (
    <div>
      {formatTime(minutes)}:{formatTime(seconds)}
    </div>
  );
}
