import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { GameData, GameElement } from '../../@types/game';
import '../../i18n/config';
import  './Game.scss'
import { AnswerModal } from '../AnswerModal/AnswerModal';
import { DemoGameDifference } from '../DemoGameDifference/DemoGameDifference';
import { incrementSteps, startGame, startGameTimer, stopGameTimer } from '../../store/slices/gameSlice';
import { useAppDispatch } from '../../store/hooks';
import { imagePlacement } from './imagePlacement';
import { SnowflakeIAndCircleImage } from './SnowflakeIAndCircleImage'

interface Props {
  isDemo?: boolean,
  isDemoGameStarted: boolean,
  setIsDemoGameStarted: (b: boolean) => void,
  gameData: GameData
}

export const Game = ({ isDemo, isDemoGameStarted, setIsDemoGameStarted, gameData }: Props) => {
  const { t } = useTranslation();
  const [elements, setElements] = useState<Array<GameElement>>([]);
  const [visibleIndices, setVisibleIndices] = useState<number[]>([]);

  const dispatch = useAppDispatch();
  const isRemoveButtonDisabled = gameData.clickCount > 5 || gameData.gameAnswerTime === '90';
  const question = gameData.gameQuestion.split('\n')[0];
  const additionalMessage = gameData.gameQuestion.split('\n')[1] || null;


  useEffect(() => {
    let elementsArray;
    const indices = Array.from({ length: 25 }, (_, index) => index);

    if (isDemo || gameData.hidingType === 'square') {
      elementsArray = Array(25).fill({ visible: true })
    } else {
      elementsArray = imagePlacement;
    }

    if (elementsArray && indices) {
      setElements(elementsArray)
      setVisibleIndices(indices)
    }
  }, [gameData.gameUid])

  useEffect(() => {
    if (isRemoveButtonDisabled) {
      dispatch(stopGameTimer())
    }
  }, [isRemoveButtonDisabled]);


  const handleRemoveElementClick = () => {
    const randomIndex = visibleIndices[Math.floor(Math.random() * visibleIndices.length)];
    setVisibleIndices(visibleIndices.filter(index => index !== randomIndex));

    const squaresCopy = [...elements];
    squaresCopy[randomIndex] = { ...squaresCopy[randomIndex], visible: false };

    setElements(squaresCopy);
    dispatch(incrementSteps());

    if (!gameData.isGameStarted) {
      dispatch(startGame());
    }

    if (!gameData.isTimerRunning) {
      dispatch(startGameTimer());
    }
  };

  return (
    <>
      <div className='game-area'>
        <img className='game-area__game-image' src={gameData.gameImg} />

        {elements
          ? elements.map((element, index) => (
            gameData.hidingType === 'square'
             ? <div
                key={index}
                className={`game-area__square ${element.visible ? '' : 'hidden'}`}
             />
             : <SnowflakeIAndCircleImage
                key={index} index={index}
                hidingType={gameData.hidingType}
                position={element}
              />
          ))
          : ''
        }
        {((isDemoGameStarted && !gameData.isGameStarted) || (!isDemo && !gameData.isGameStarted)) &&
          <div className="game-area__game-question-block">
            <span/>
            { !isDemo &&
              <div className='game-question'>
                {question ? question : t('gameArea_game-question-loading')}
                { additionalMessage &&
                  <span className='game-question__additional-text'>{additionalMessage}</span>
                }
              </div>
            }
            {isDemo &&
              <>
                <div className='game-question--demo'>
                  <b>{gameData.gameQuestion ? gameData.gameQuestion : t('gameArea_game-question-loading')}</b>
                  <p>Here, in the demo version of the game, you can test your strength and practice solving pictures from Socialness.</p>
                </div>
                <div className='game-question--demo-mobile'>
                  <b>{gameData.gameQuestion ? gameData.gameQuestion : t('gameArea_game-question-loading')}</b>
                  <p>Here, in the demo version of the game, you can test your strength and practice solving pictures from Socialness.</p>
                </div>
              </>
            }
            <div className='game-question--subtext'>{t('gameArea_subtitle')}</div>
          </div>
        }
      </div>

      {(!isDemoGameStarted && isDemo) &&
        <div className='game-page__action-buttons'>
          <button onClick={() => setIsDemoGameStarted(!isDemoGameStarted)} className='game-page__play-demo'>Play demo</button>
        </div>
      }
      {(isDemoGameStarted || !isDemo) &&
        <div className='game-page__action-buttons'>
          <button
            onClick={handleRemoveElementClick}
            className={`game-page__remove-button ${isRemoveButtonDisabled ? 'disabled' : ''}`}
            disabled={ isRemoveButtonDisabled }
          >
            Remove
          </button>
          <AnswerModal isDemo={isDemo} gameData={gameData} />
        </div>
      }
    </>
  )
};
