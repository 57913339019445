import React from 'react';
import './Modal.scss';
import {ReactComponent as CloseIcon} from '../../assets/images/cross.svg';

interface ModalProps {
  children: JSX.Element | JSX.Element[] | null
  isModalOpen: boolean,
  closeModal: (isModalOpen: boolean) => void;
}

export const Modal = ({children, isModalOpen, closeModal}: ModalProps) => {
  const handleCloseModal = () => {
    closeModal(!isModalOpen)
  }
  
  return (
    isModalOpen
      ? (<div className='modal'>
        <div className='modal__content'>
          <span onClick={handleCloseModal} className="modal__close"><CloseIcon /></span>
          {children}
        </div>
      </div>)
      : null
  )
};
