import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Footer } from './components/Footer/Footer';
import { Navbar } from './components/Navbar/Navbar';
import { GamePage } from './components/GamePage';
import { TryItYourself } from './components/TryItYourself/TryItYourself';
import { Page404 } from './page404';

function App() {
  return (
    <div className="App">
        <Router>
          <Navbar />

          <Routes>
            <Route path='/demo' element={<GamePage isDemo />} />
            <Route path='/game/:gameId' element={<GamePage />} />
            <Route path='/try-it' element={<TryItYourself />} />
            <Route path="*" element={<Page404 />} />
          </Routes>

          <Footer />
        </Router>
    </div>
  );
}

export default App;
