import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { GameData, GlobalState } from '../../@types/game';
import './AnswerModal.scss';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from '../Modal/Modal';
import { postGameAnswerData, startGameTimer, stopGameTimer } from '../../store/slices/gameSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Spinner } from '../Spinner/Spinner';

interface Props {
  isDemo?: boolean
  gameData?: GameData
}

export const AnswerModal = ({ isDemo, gameData }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const { t } = useTranslation();

  const { isError, isLoading, isSuccess } = useAppSelector((state: GlobalState) => state.game);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [answer, setAnswer] = useState('');
  const isFirstClickDone = gameData ? gameData.clickCount > 0 : true;


  useEffect(() => {
    if (isSuccess && !isLoading && isModalOpen) {
      navigate('/try-it')
    }

    if (isError && !isLoading && isModalOpen) {
      toast.error(errorMsg)
    }
  }, [isError, isLoading, isSuccess]);

  const errorMsg = () => (
    <div className='answer-error-msg'>
      <h3>Error occurred!</h3>
      <span>Something gone wrong. Try again latter</span>
    </div>
  )

  const handleOpenModal = () => {
    setIsModalOpen(true);
    dispatch(stopGameTimer())
  };
  const handleCloseModal = () => {
    setIsModalOpen(!isModalOpen);

    if (Number(gameData?.clickCount) < 6 && gameData?.isGameStarted) {
      dispatch(startGameTimer())
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (isDemo) {
      navigate('/try-it');
    } else {
      if (gameData) {
        const { clickCount, gameAnswerTime, gameUid } = gameData;
        const answerData = {
          guestName: name,
          attempts: Number(clickCount),
          seconds: Number(gameAnswerTime),
          answer: answer,
        };

        dispatch(postGameAnswerData({gameUid, answerData}));
      }
    }
  }

  return (
    <>
      <div onClick={handleOpenModal}>
        <button
          disabled={!isFirstClickDone}
          style={isFirstClickDone ? { filter: 'drop-shadow(0 4px 0 #E69438) '} : { filter: 'drop-shadow(0 4px 0 #b7b7b7) '}}
          className={`answer-button ${!isFirstClickDone ? 'disabled' : ''}`}>{t('answerModalButton')}
        </button>
      </div>
      <Modal
        isModalOpen={isModalOpen}
        closeModal={handleCloseModal}
      >
        <div className='answer-modal'>
          {isLoading &&
            <Spinner />
          }
          <h1 className='answer-modal__title'>{t('answerModal_title')}</h1>
          <h2 className='answer-modal__subtitle'>
            { !isDemo
              ? `${t('answerModal_subtitle')}`
              : `${t('answerModal_demo-game-subtitle')} 😊`
            }
          </h2>
          <form className='answer-modal__form' onSubmit={(e) => handleSubmit(e) }>
            { !isDemo &&
              <>
                <label className='answer-modal__form-lable' htmlFor='name'>
                  {t('answerModal_form-name-label')}
                </label>
                <input
                  className='answer-modal__form-input'
                  placeholder={t('answerModal_form-name-input-placeholder')}
                  id='name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type='text'
                  name='name'
                  required
                />
              </>
            }
            <label className='answer-modal__form-lable' htmlFor='answer'>
              {t('answerModal_form-answer-label')}
            </label>
            <textarea
              className='answer-modal__form-textarea'
              placeholder={t('answerModal_form-answer-textarea-placeholder')}
              id='answer'
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              name='answer'
              required
            >
            </textarea>
            <button
              className='answer-modal__action-button'
              type="submit"
            >
              {t('answerModal_form-action-button')}
            </button>
          </form>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        />
      </Modal>
    </>
  );
};