import React, { useState, useEffect } from 'react';
import { GameElement } from '../../@types/game';
import circle from '../../assets/images/gameCircle.svg';
import snowflake from '../../assets/images/gameSnowflake.svg';

interface Props {
  index: number,
  hidingType: string,
  position: GameElement,
}
export const SnowflakeIAndCircleImage = ({ index, hidingType, position }: Props) => {
  const [screenWidth, setScreenWidth] = useState(getCurrentScreenWidth());
  const isMobile = screenWidth <= 1080
  const positionTop = !isMobile ? position.top : ((Number(position.top) / 1.3) - 40);
  const positionLeft = !isMobile ? position.left : ((Number(position.left) / 1.3) - 40);
  const positionScale = !isMobile ? position.scale : (Number(position.scale) / 1.3);

  function getCurrentScreenWidth (): number {
    return window.innerWidth
  }

  useEffect(() => {
    const updateWidth = () => {
      setScreenWidth(getCurrentScreenWidth())
    }
    window.addEventListener('resize', updateWidth);

    return(() => {
      window.removeEventListener('resize', updateWidth);
    })
  }, [screenWidth])


  return (
    <img
      key={index}
      style={{
        transform: `scale(${positionScale})` ,
        top: positionTop,
        left: positionLeft,
        zIndex: 2,
        position: 'absolute'
      }}
      className={!position.visible ? 'hidden' : ''}
      src={
        hidingType === 'snowflake'
          ? snowflake
          : circle
      }
    />
  );
};