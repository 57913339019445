import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import { ReactComponent as BrandLogo } from '../../assets/images/logo.svg';
import { ReactComponent as Hamburger } from '../../assets/images/hamburger.svg';
import './Navbar.scss';
import { InfoModal } from '../InfoModal/InfoModal';
import { useAppSelector } from '../../store/hooks';
import { GlobalState } from '../../@types/game';

export const Navbar = () => {
  const { t } = useTranslation();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { gameData } = useAppSelector((state: GlobalState) => state.game);
  const isInfoModalViewed = localStorage.getItem('isInfoModalViewed') || false;
  let activeGameId = sessionStorage.getItem('gameId') || '';


  useEffect(() => {
    activeGameId = sessionStorage.getItem('gameId') || ''
  }, [gameData.gameUid]);

  useEffect(() => {
    if (showMobileMenu) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [showMobileMenu])

  const handleShowMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu)
  }

  return (
    <nav className="navbar">
      <div className="navbar__container">
        <div className="navbar__logo">
          <a href='https://www.socialness.us'>
            <BrandLogo />
            <span>Socialness</span>
          </a>
        </div>
        <div className="navbar__title">
          {t('navbar_title')}
        </div>
        <div className="navbar__nav-elements">
          <ul>
            <li>
              <NavLink to={activeGameId ? `/game/${activeGameId}` : '/demo'}>{t('navbar_activeGame')}</NavLink>
            </li>
            <li>
              <NavLink to="/demo">{t('navbar_playDemo')}</NavLink>
            </li>
          </ul>
        </div>
        <div className="navbar__mobile-menu-icon" onClick={handleShowMobileMenu}>
          <Hamburger />
        </div>
        {showMobileMenu &&
          <div className="navbar__mobile-menu-elements">
            <ul>
              <li onClick={handleShowMobileMenu}>
                <NavLink to={activeGameId ? `/game/${activeGameId}` : '/demo'}>{t('navbar_activeGame')}</NavLink>
              </li>
              <li onClick={handleShowMobileMenu}>
                <NavLink to="/demo">{t('navbar_playDemo')}</NavLink>
              </li>
            </ul>
            <div><InfoModal showModal={!isInfoModalViewed}/></div>
          </div>
        }
      </div>
    </nav>
  )
};