import React, { useEffect, useState } from 'react';
import './GamePage.scss';
import { GlobalState } from '../@types/game';
import { useParams } from 'react-router-dom';
import { ReactComponent as ClickIcon } from '../assets/images/click.svg';
import { ReactComponent as HandIcon } from '../assets/images/hand.svg';
import { ReactComponent as TimerIcon } from '../assets/images/timer.svg';
import { Page404 } from '../page404';
import { Game } from './Game/Game';
import { InfoModal } from './InfoModal/InfoModal';
import { DemoGameDifference } from './DemoGameDifference/DemoGameDifference';
import { Timer } from './Timer/Timer';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { getGameData, setDemoGameData, resetStatuses } from '../store/slices/gameSlice';

interface Props {
  isDemo?: boolean
}

export const GamePage = ({ isDemo }: Props) => {
  const dispatch = useAppDispatch();
  const [isDemoGameStarted, setIsDemoGameStarted] = useState(false);
  const { gameData, isError, isLoading, isSuccess } = useAppSelector((state: GlobalState) => state.game);
  const { gameId } = useParams();
  const isInfoModalViewed = localStorage.getItem('isInfoModalViewed') || false;

  useEffect(() => {
    if (!isDemo) {
      const currentGameId = gameId || '';
      const sessionStorageGameId = sessionStorage.getItem('gameId') || '';

      if (currentGameId !== sessionStorageGameId) {
        sessionStorage.setItem('gameId', currentGameId)
      }
    }
  }, [gameId]);

  useEffect(() => {
    if (isDemo) {
      dispatch(setDemoGameData())
    } else {
      dispatch(getGameData(`${gameId}`))
    }
  }, [isDemo])

  useEffect(() => {
    if (isSuccess) {
      dispatch(resetStatuses())
    }
  }, [gameData]);

  if (!gameData.gameQuestion && !gameData.gameImg && !gameData.hidingType && !isLoading) {
    return <Page404 />
  }


  return (
    <div className='game-page'>
      <div className='game-page__info-modal-container'>
        <div className='game-page__info-modal-button'>
          <InfoModal showModal={!isInfoModalViewed} />
        </div>
      </div>

      <div className='game-page__game-container'>
        <Game
          isDemo={isDemo}
          isDemoGameStarted={isDemoGameStarted}
          setIsDemoGameStarted={setIsDemoGameStarted}
          gameData={gameData}
        />
      </div>

      <div className='game-page__game-info-container'>
        <div className='game-page__game-info-panel'>
          <div>
            <ClickIcon /> <div className='clicks'>{gameData.clickCount} of 6</div>
          </div>
          <div className='game-timer'>
            <TimerIcon /> <Timer isTimerRunning={gameData.isTimerRunning} gameAnswerTime={gameData.gameAnswerTime}/>
          </div>
        </div>
      </div>
    </div>
  );
};
