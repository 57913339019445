export const imagePlacement = [
  {
    top: -20,
    left: -30,
    scale: 1.4,
    visible: true,
  },
  {
    top: 60,
    left: 80,
    scale: 1.4,
    visible: true,
  },
  {
    top: -40,
    left: 100,
    scale: 0.9,
    visible: true,
  },
  {
    top: 15,
    left: 210,
    scale: 1.7,
    visible: true,
  },
  {
    top: 100,
    left: -30,
    scale: 0.9,
    visible: true,
  },
  {
    top: 190,
    left: -10,
    scale: 1.3,
    visible: true,
  },
  {
    top: 180,
    left: 90,
    scale: 0.9,
    visible: true,
  },
  {
    top: 270,
    left: -40,
    scale: 0.9,
    visible: true,
  },
  {
    top: 260,
    left: 80,
    scale: 0.9,
    visible: true,
  },
  {
    top: -30,
    left: 320,
    scale: 0.8,
    visible: true,
  },
  {
    top: 30,
    left: 340,
    scale: 1.1,
    visible: true,
  },
  {
    top: 140,
    left: 180,
    scale: 1.1,
    visible: true,
  },
  {
    top: 130,
    left: 285,
    scale: 0.9,
    visible: true,
  },
  {
    top: 160,
    left: 350,
    scale: 1.2,
    visible: true,
  },
  {
    top: 230,
    left: 250,
    scale: 1.0,
    visible: true,
  },
  {
    top: 270,
    left: 180,
    scale: 1.5,
    visible: true,
  },
  {
    top: 260,
    left: 350,
    scale: 1.5,
    visible: true,
  },
  {
    top: 320,
    left: 10,
    scale: 0.9,
    visible: true,
  },
  {
    top: 360,
    left: 80,
    scale: 1.2,
    visible: true,
  },
  {
    top: 360,
    left: -30,
    scale: 0.9,
    visible: true,
  },
  {
    top: 370,
    left: 200,
    scale: 1.2,
    visible: true,
  },
  {
    top: 340,
    left: 300,
    scale: 1.4,
    visible: true,
  },
  {
    top: 370,
    left: 370,
    scale: 1,
    visible: true,
  },
  {
    top: 90,
    left: 380,
    scale: 1,
    visible: true,
  },
  {
    top: -20,
    left: 380,
    scale: 1,
    visible: true,
  },
];