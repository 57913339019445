import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from '../Modal/Modal';
import './InfoModal.scss';
import infoHeaderImg from '../../assets/images/InfoModalHeaderImage.png';
import infoIcon from '../../assets/images/infoCircle.png';

interface Props {
  showModal?: boolean
}
export const InfoModal = ({ showModal }: Props) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(showModal || false);
  const handleCloseModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleAcceptButtonClick = () => {
    localStorage.setItem('isInfoModalViewed', 'true');
    setIsModalOpen(!isModalOpen);
  }

  return (
    <>
      <div className='info-button' onClick={() => setIsModalOpen(true)}>
        <img src={infoIcon} alt='Information icon' />
        <span className="info-button__text">{t('shared_howToPlay')}</span>
      </div>
      <Modal
        isModalOpen={isModalOpen}
        closeModal={handleCloseModal}
      >
        <div className='info-modal'>
          <div className='info-modal__header'>
            <img src={infoHeaderImg} alt='Info modal header image'/>
          </div>
          <h1 className='info-modal__title'>{t('infoModal_title')}</h1>
          <div className='info-modal__content'>
            <p>
              {t('infoModal_content.part1')}
            </p>
            <p>
              <Trans
                i18nKey="infoModal_content.part2"
                defaults="You have <bold>{{count}}</bold> in which another brick will be removed from the picture revealing more of the photo."
                values={{ count: '6 turns'}}
                components={{ bold: <strong /> }}
              />
            </p>
            <p>
              {t('infoModal_content.part3')}
            </p>
            <p>
              {t('infoModal_content.part4')}
            </p>
            <p>
              {t('infoModal_content.part5')}
            </p>
            <p>
              <Trans
                i18nKey="infoModal_content.part6"
                defaults="• 1 point for playing<br>• 1 point for answering correctly"
                components={{ br: <br/> }}
              />
            </p>
          </div>
          <button onClick={handleAcceptButtonClick} className='info-modal__action-button'>{t('infoModal_button')}</button>
        </div>
      </Modal>
    </>
  );
};
